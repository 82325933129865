import * as React from 'react';

import { flexgutter } from '../components/common';
import styled from '@emotion/styled';
import TosPage from './terms-service';

type Props = {
  path: string;
};

const Privacy = styled.div`
  ${flexgutter}
`;

const IndexPage: React.FC = (props) => TosPage(props);

export default IndexPage;
